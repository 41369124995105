<template>
  <b-card-text class="partner">
    <p>
      {{ $t('referral.tabPartnerProgram.firstContentTab') }}
    </p>

    <ul class="list-content">
      <li>{{ $t('referral.tabPartnerProgram.contentProfitable') }}</li>
      <li>{{ $t('referral.tabPartnerProgram.contentFlexible') }}</li>
      <li>{{ $t('referral.tabPartnerProgram.contentScalable') }}</li>
      <li>{{ $t('referral.tabPartnerProgram.contentSimple') }}</li>
    </ul>

    <h3>
      {{ $t('referral.tabPartnerProgram.titlePleaseLeaveInfo') }}
    </h3>

    <div class="partner-content d-flex justify-content-space-between flex-wrap">

      <div class="form-partner mr-0 mr-md-4">
        <validation-observer ref="partnerValidation">
          <b-form class="auth-partner-form mt-2">
            <!-- username -->
            <b-form-group class="my-2">
              <div class="d-flex justify-content-between">
                <label for="fullname">
                  {{ $t('common.labelName') }}
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="fullname"
                :name="$t('common.labelFullName')"
                rules="required"
              >
                <b-form-input
                  v-model="fullname"
                  id="partner-fullname"
                  name="partner-fullname"
                  class="input-height"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('common.placeholderName')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label-for="partner-email"
            >
              <div class="d-flex justify-content-between">
                <label for="email">
                  {{ $t('common.labelEmail') }}
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="email"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model.trim="userEmail"
                  id="partner-email"
                  name="partner-email"
                  class="input-height"
                  :placeholder="$t('common.placeholderEmail')"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- reference -->
            <b-form-group class="my-2">
              <div class="d-flex justify-content-between">
                <label for="whatsapp">
                  Whatsapp
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="whatsapp"
                name="Whatsapp"
                rules="required"
              >
                <b-form-input
                  v-model="whatsapp"
                  id="partner-whatsapp"
                  name="partner-whatsapp"
                  class="input-height"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('common.placeholderWhatsapp')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- facebook -->
            <b-form-group
              label-for="partner-facebook"
            >
              <div class="d-flex justify-content-between">
                <label for="facebook">
                  {{ $t('referral.tabPartnerProgram.labelFacebookProfileLink') }}
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="facebook"
                name="Facebook"
                rules="required|url"
              >
                <b-form-input
                  v-model.trim="facebook"
                  id="partner-facebook"
                  class="form-control-merge input-height"
                  name="partner-facebook"
                  :placeholder="$t('common.placeholderFacebook')"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- message -->
            <b-form-group
              label-for="partner-message"
            >
              <div class="d-flex justify-content-between">
                <label for="partnerMessage">
                  {{ $t('referral.tabPartnerProgram.labelMessage') }}
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="partnerMessage"
                :name="$t('referral.tabPartnerProgram.labelMessage')"
                rules="required|messageLength"
              >
                <b-form-textarea
                  v-model.trim="partnerMessage"
                  id="partner-message"
                  class="form-control-merge input-height"
                  name="partner-message"
                  :placeholder="$t('referral.tabPartnerProgram.textWriteSomething')"
                  rows="4"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <btn-loading
              id="trigger-send-information"
              span-id="trigger-send-information-child"
              class="mt-3 mb-4 input-height"
              variant-convert="btn-submit"
              block
              type="submit"
              :loading="loading"
              @click.prevent="validationForm"
            >
              {{ $t('referral.tabPartnerProgram.textSendInformation') }}
            </btn-loading>
          </b-form>
        </validation-observer>
      </div>

      <div class="address-info">
        <b-img
          alt="logo"
          name="logo"
          :src="logo"
          class="mt-2"
        />

        <ul class="address-info-detail mt-2">
          <li>
            <div class="d-flex flex-column">
              <p class="m-0 d-flex">
                <feather-icon
                  size="24"
                  icon="MapPinIcon"
                  class="ic-address"
                />
                <span>
                  {{ $t('referral.tabPartnerProgram.textAddress') }}:
                </span>
              </p>
              <div class="address-offices">
                <p class="m-0">
                  <span class="underline decoration-solid">HQ:</span> <span>55 Le Quang Hoa - Danang - Vietnam</span>
                </p>
                <p class="underline decoration-solid m-0">
                  {{ $t('referral.tabPartnerProgram.textOffices') }}:
                </p>
                <div>
                  <p class="m-0">
                    - 68 Circular Road #02-01 - Singapore - 049422
                  </p>
                  <p class="m-0">
                    - 102 E Healey St #317 - Champaign, IL 61820, USA
                  </p>
                </div>
              </div>
            </div>
          </li>
          <li>
            <feather-icon
              size="18"
              icon="PhoneCallIcon"
            />
            <p>{{ $t('referral.tabPartnerProgram.textHotline') }}: <a href="tel:+84944399996">0944.399.996</a></p>
          </li>
          <li>
            <feather-icon
              size="18"
              icon="MailIcon"
            />
            <p>Email: <a href="mailto:support@ecomdymedia.com">support@ecomdymedia.com</a></p>
          </li>
          <li>
            <feather-icon
              size="24"
              icon="SmartphoneIcon"
            />
            <p>Telegram: {{ $t('referral.tabPartnerProgram.textGetLinkFromPlatform', {platformName: platformName}) }}</p>
          </li>
          <li>
            <feather-icon
              size="24"
              icon="HeadphonesIcon"
            />
            <p>Whatsapp: {{ $t('referral.tabPartnerProgram.textGetLinkFromPlatform', {platformName: platformName}) }}</p>
          </li>
          <li>
            <feather-icon
              size="24"
              icon="FacebookIcon"
            />
            <p>Link fanpage: <a href="https://www.facebook.com/EcomdyMedia">https://www.facebook.com/EcomdyMedia</a></p>
          </li>
        </ul>
      </div>
    </div>

  </b-card-text>
</template>

<script>
import axios from '@axios'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardText, BImg,
  BForm, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import { $themeConfig } from '@themeConfig'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import {
  required,
  email,
  url,
  messageLength,
} from '@validations'
import envMixin from '@/mixins/envMixin'

export default {
  components: {
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BImg,
    BtnLoading,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  mixins: [toastification, envMixin],
  data() {
    return {
      loading: false,
      fullname: '',
      userEmail: '',
      whatsapp: '',
      facebook: '',
      partnerMessage: '',
      required,
      email,
      url,
      messageLength,
      imgUrl: require('@/assets/images/logo/logo-ecomdy-media.svg'),
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  computed: {
  },
  methods: {
    cleanForm() {
      this.fullname = ''
      this.userEmail = ''
      this.whatsapp = ''
      this.facebook = ''
      this.partnerMessage = ''
      this.$nextTick(() => this.$refs.partnerValidation.reset())
    },
    async validationForm() {
      const success = await this.$refs.partnerValidation.validate()
      if (success) {
        this.loading = true
        const params = {
          name: this.fullname,
          email: this.userEmail,
          whatsApp: this.whatsapp,
          fbProfileUrl: this.facebook,
          message: this.partnerMessage,
        }
        axios.post('/api/partners', params)
          .then(res => {
            // Perform Success Action
            if (res) {
              this.toastSuccess(this.$t('referral.tabPartnerProgram.toastSubmitInfoSuccess'))
            }
            this.cleanForm()
          })
          .catch(error => {
            // error.response.status Check status code
            this.toastFailure(error.toString())
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>
<style scoped>
a {
  color: #6e44ff;
}

.ic-address{
  margin-right: 20px;
}

.address-offices{
  margin-left: 44px;
  margin-bottom: 1rem;
}

.list-content{
  list-style-type: disc;
  margin-left: 1rem;
}
</style>
