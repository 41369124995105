<template>
  <div>
    <b-card-text class="m-0">
      {{ $t('referral.tabEarnMoney.contentYouAreTheClient', { companyName: companyName }) }}
    </b-card-text>

    <h3 class="my-2">
      {{ $t('referral.tabEarnMoney.titleJoinAffiliate', { companyName: companyName }) }}
    </h3>

    <p>
      {{ $t('referral.tabEarnMoney.contentJoinAffiliate', { companyName: companyName }) }}
    </p>

    <h3 class="my-2">
      {{ $t('referral.tabEarnMoney.titleWhoShouldJoin', { companyName: companyName }) }}
    </h3>

    <p>
      {{ $t('referral.tabEarnMoney.contentWhoShouldJoin', { companyName: companyName }) }}
    </p>

    <b-card-text class="">
      <h3 class="my-2">
        {{ $t('referral.tabEarnMoney.titleHowAffiliateProgramWork', { companyName: companyName }) }}

      </h3>

      <div class="step-wrapper">

        <div class="step-block">
          <div class="step-icon">
            1
          </div>
          <div class="step-body">
            <h4>
              {{ $t('referral.tabEarnMoney.firstStepAffiliateProgramWork') }}
            </h4>
            <p>{{ $t('referral.tabEarnMoney.subFirstStepAffiliateProgramWork') }}</p>

            <div class="d-md-flex align-items-md-end mt-2">
              <div class="mr-2 referral-link">
                <label
                  for="referral-code"
                  class="text-uppercase font-medium"
                >
                  {{ $t('referral.tabEarnMoney.labelReferralLink') }}
                </label>

                <b-form-input
                  v-model="referralCode"
                  id="referral-code"
                  name="referral-code"
                  class="referral-code"
                  disabled
                />
              </div>
              <b-button
                size="md"
                variant="secondary"
                class="copy-btn mr-1"
                @click="copyReferralLink"
              >
                <span class="mr-25 align-middle">
                  {{ $t('referral.tabEarnMoney.btnCopyThisLink') }}
                </span>
              </b-button>
            </div>
          </div>
        </div>
        <!-- end step 1 -->

        <div class="step-block">
          <div class="step-icon">
            2
          </div>
          <div class="step-body">
            <h4>
              {{ $t('referral.tabEarnMoney.secondStepAffiliateProgramWork') }}
            </h4>
            <p>
              {{ $t('referral.tabEarnMoney.subSecondStepAffiliateProgramWork') }}
            </p>

            <b-row>
              <b-col
                class="banner-left"
                lg="3"
                cols="12"
              >
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  :label="$t('referral.tabEarnMoney.labelBannerType')"
                >
                  <b-form-radio-group
                    v-model="bannerSelected"
                    :options="bannerOptions"
                    class="share-banners"
                    name="radio-inline"
                    buttons
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                  />
                </b-form-group>
              </b-col>
              <b-col
                class="banner-right"
                lg="9"
                cols="12"
              >
                <div class="banner-wrapper">
                  <b-img
                    :src="bannerSource"
                    alt="Refferal"
                    fluid
                  />

                  <br>

                  <b-button
                    size="large"
                    variant="outline-primary"
                    class="btn-tour-skip mr-1 mt-3 d-inline-block"
                    @click="copyBannerCode"
                  >
                    <span class="mr-25 align-middle">
                      {{ $t('referral.tabEarnMoney.btnCopyThisLink') }}
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

            <b-form-textarea
              id="textarea-default"
              placeholder="Textarea"
              rows="3"
              :value="bannerCode"
              disabled
              class="mt-2 d-none"
            />

          </div>
        </div>
        <!-- end step 2 -->

        <div class="step-block">
          <div class="step-icon">
            3
          </div>
          <div class="step-body">
            <h4>
              {{ $t('referral.tabEarnMoney.thirdStepAffiliateProgramWork', { platformName: platformName }) }}
            </h4>
            <p>
              {{ $t('referral.tabEarnMoney.contentThirdStepAffiliateProgramWork', { companyName: companyName }) }}
            </p>

            <b-table
              responsive="sm"
              :striped="true"
              :bordered="true"
              :items="items"
            />

          </div>
        </div>
        <!-- end step 3 -->

        <div class="step-block">
          <div class="step-icon">
            4
          </div>
          <div class="step-body">
            <h4>
              {{ $t('referral.tabEarnMoney.fourthStepAffiliateProgramWork') }}
            </h4>
            <h4 class="mt-2">
              {{ $t('account.note') }}:
            </h4>
            <ul>
              <li>
                {{ $t('referral.tabEarnMoney.firstStepNote') }}
              </li>
              <li>
                {{ $t('referral.tabEarnMoney.secondStepNote') }}:
                <a
                  v-if="!isExclusivePartner"
                  href="mailto:support@ecomdymedia.com"
                >support@ecomdymedia.com</a>
                <span
                  v-else
                  class="text-purple cursor-pointer"
                  @click.prevent="goToMessengerUrl"
                >messenger</span>. {{ $t('referral.tabEarnMoney.textDecisionsAreFinal', { companyName: companyName }) }}
              </li>
              <li>
                {{ $t('referral.tabEarnMoney.finalStepNote', { companyName: companyName }) }}
              </li>
            </ul>
          </div>
        </div>
        <!-- end step 4 -->
      </div>
    </b-card-text>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCardText,
  BFormInput,
  BFormRadioGroup,
  BFormGroup,
  BFormTextarea,
  BTable,
  BImg,
  BRow, BCol,
} from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'

export default {
  components: {
    BButton,
    BCardText,
    BFormInput,
    BFormRadioGroup,
    BFormGroup,
    BFormTextarea,
    BTable,
    BImg,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },

  mixins: [envMixin],
  props: {
    userData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      bannerSelected: 1,
      bannerOptions: [
        {
          text: 'Size 300×250',
          value: '1',
        },
        {
          text: 'Size 300x300',
          value: '2',
        },
        {
          text: 'Size 300x600',
          value: '3',
        },
        {
          text: 'Size 320x100',
          value: '4',
        },
        {
          text: 'Size 336x280',
          value: '5',
        },
        {
          text: 'Size 600x314',
          value: '6',
        },
        {
          text: 'Size 728×90',
          value: '7',
        },
      ],
      siteUrl: process.env.VUE_APP_SITE_URL,
      items: [
        {
          [this.$t('referral.tabEarnMoney.tableEarnMoney.columnMilestone')]: this.$t('referral.tabEarnMoney.tableEarnMoney.textBasicMilestone'),
          [this.$t('referral.tabEarnMoney.tableEarnMoney.columnReferredAdsSpending')]: '$1.000',
          [this.$t('referral.tabEarnMoney.tableEarnMoney.columnBonus')]: '$15',
          [this.$t('referral.tabEarnMoney.tableEarnMoney.columnNotes')]: '',
        },
        {
          [this.$t('referral.tabEarnMoney.tableEarnMoney.columnMilestone')]: this.$t('referral.tabEarnMoney.tableEarnMoney.textStandardMilestone'),
          [this.$t('referral.tabEarnMoney.tableEarnMoney.columnReferredAdsSpending')]: '$2.000',
          [this.$t('referral.tabEarnMoney.tableEarnMoney.columnBonus')]: '$15',
          [this.$t('referral.tabEarnMoney.tableEarnMoney.columnNotes')]: '',
        },
        {
          [this.$t('referral.tabEarnMoney.tableEarnMoney.columnMilestone')]: this.$t('referral.tabEarnMoney.tableEarnMoney.textProMilestone'),
          [this.$t('referral.tabEarnMoney.tableEarnMoney.columnReferredAdsSpending')]: '$10.000',
          [this.$t('referral.tabEarnMoney.tableEarnMoney.columnBonus')]: '$50',
          [this.$t('referral.tabEarnMoney.tableEarnMoney.columnNotes')]: '',
        },
      ],
    }
  },
  computed: {
    referralCode() {
      return `${process.env.VUE_APP_LOGIN_URL}/register?refcode=${this.userData?.data?.referralTracking?.code}`
    },
    bannerCode() {
      return `<a href="${this.referralCode}"><img src="${process.env.VUE_APP_MEDIA_URL}/statics/banners/${this.bannerSelected}.png"></a>`
    },
    bannerSource() {
      // eslint-disable-next-line import/no-dynamic-require
      return require(`@/assets/images/banner/${this.bannerSelected}.png`)
    },
  },

  methods: {
    copyReferralLink() {
      this.$copyText(this.referralCode).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Referral code link copied',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
            variant: 'Danger',
          },
        })
      })
    },
    copyBannerCode() {
      this.$copyText(this.bannerCode).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Banner code copied',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.referral-link {
  @media(min-width: 768px) {
    width: 64%;
  }
}
</style>
