<template>
  <b-card-text class="payouts">
    <div class="payouts-content d-flex flex-wrap justify-content-between">
      <div class="left">
        <h4>
          {{ $t('referral.tabPayouts.textUnpaidEarnings') }}
        </h4>
        <p>
          <span class="font-medium">
            {{ $t('referral.tabPayouts.textEarnings') }}:
          </span>
          ${{ bonus }}
        </p>
        <p>
          {{ $t('referral.tabPayouts.textReferralBonusesCanUse', {companyName: companyName}) }}.
        </p>
      </div>

      <div class="right">
        <span class="label label-fund">
          {{ $t('referral.tabPayouts.textAvailableFunds') }}
        </span>
        <br>
        <b-spinner
          v-if="loading"
          variant="secondary"
          small
        />
        <div
          v-else
          class="h1"
        >
          ${{ userBalance }}
        </div>
        <hr>

        <div class="d-flex flex-row flex-column w-100 mb-1">
          <span
            class="label user-label font-medium"
          >
            {{ $t('referral.tabPayouts.textClientName') }}:
          </span>
          <span class="value">
            {{ fullName }}
          </span>
        </div>
        <div class="d-flex flex-row flex-column w-100 mb-1">
          <span class="label user-label font-14 font-medium">
            {{ $t('referral.tabPayouts.textClientID') }}:
          </span>
          <span class="value">
            {{ userId }}
          </span>
        </div>
      </div>

    </div>
    <!-- payouts-content -->

    <h3 class="my-2">
      {{ $t('referral.tabPayouts.textReferralStats') }}
    </h3>
    <table class="table table-hover table-bordered">
      <tr>
        <th>
          {{ $t('referral.tabPayouts.textRefferalCode') }}
        </th>
        <td>
          {{ code }}
        </td>
      </tr>
      <tr>
        <th>
          {{ $t('referral.tabPayouts.textTotalBonusReceived') }}
        </th>
        <td>
          {{ receivedAmount }}
        </td>
      </tr>
      <tr>
        <th>
          {{ $t('referral.tabPayouts.textReferralLinkClicks') }}
        </th>
        <td>
          {{ clicks }}
        </td>
      </tr>
      <tr>
        <th>
          {{ $t('referral.tabPayouts.textReferralRegistration') }}
        </th>
        <td>
          {{ countUsed }}
        </td>
      </tr>
    </table>

    <h3 class="mt-3 mb-2">
      {{ $t('referral.tabPayouts.textPayoutHistory') }}
    </h3>
    <table
      v-if="stats.length > 0"
      class="table table-hover table-bordered"
    >
      <thead>
        <tr>
          <th scope="col">
            {{ $t('referral.tabPayouts.textBussinessReferralID') }}
          </th>
          <th scope="col">
            {{ $t('referral.tabPayouts.textCurrentSpentAds') }}
          </th>
          <th scope="col">
            {{ $t('referral.tabPayouts.textMilestone') }}
          </th>
          <th
            scope="col"
            width="40"
          >
            {{ $t('referral.tabPayouts.textBonusAmount') }}
          </th>
          <th scope="col">
            {{ $t('referral.tabPayouts.textDate') }}
          </th>
          <th scope="col">
            {{ $t('referral.tabPayouts.textStatus') }}
          </th>
        </tr>
      </thead>
      <tbody
        v-for="stat in stats"
        :key="stat._id"
      >
        <tr
          v-if="stat._id"
          :key="stat._id"
        >
          <!--Bussiness Referral ID-->
          <th
            scope="row"
            class="id-no"
            align="right"
            rowspan="3"
          >
            #{{ stat._id }}
          </th>
          <!--CURRENT SPENT ADS-->
          <th
            align="left"
            rowspan="3"
          >
            ${{ stat.adsSpentTracking.currentSpentAdsMoney.toFixed(2) }}
          </th>
          <td>
            ${{ m1 }}
          </td>
          <td align="center">
            ${{ stat.adsSpentTracking.milestones[m1].payoutMoney }}
          </td>
          <td>{{ dayjs(stat.adsSpentTracking.milestones[m1].createdAt).format('YYYY-MMM-DD') }}</td>
          <td>
            <span
              class="badge rounded-pill"
              :class="getStatusClass(stat.adsSpentTracking.milestones[m1].status)"
            >
              {{ formatStatus(stat.adsSpentTracking.milestones[m1].status) }}
            </span>
          </td>
        </tr>
        <tr
          v-if="stat.adsSpentTracking.milestones[m2]"
          :key="`${stat._id}m2`"
        >
          <td>
            ${{ m2 }}
          </td>
          <td align="center">
            ${{ stat.adsSpentTracking.milestones[m2].payoutMoney }}
          </td>
          <td>{{ dayjs(stat.adsSpentTracking.milestones[m2].createdAt).format('YYYY-MMM-DD') }}</td>
          <td>
            <span
              class="badge rounded-pill"
              :class="getStatusClass(stat.adsSpentTracking.milestones[m2].status)"
            >
              {{ formatStatus(stat.adsSpentTracking.milestones[m2].status) }}
            </span>
          </td>
        </tr>
        <tr
          v-if="stat.adsSpentTracking.milestones[m3]"
          :key="`${stat._id}m3`"
        >
          <td>
            ${{ m3 }}
          </td>
          <td align="center">
            ${{ stat.adsSpentTracking.milestones[m3].payoutMoney }}
          </td>
          <td>{{ dayjs(stat.adsSpentTracking.milestones[m3].createdAt).format('YYYY-MMM-DD') }}</td>
          <td>
            <span
              class="badge rounded-pill"
              :class="getStatusClass(stat.adsSpentTracking.milestones[m3].status)"
            >
              {{ formatStatus(stat.adsSpentTracking.milestones[m3].status) }}
            </span>
          </td>
        </tr>

      </tbody>
    </table>

    <div v-else>
      {{ $t('referral.tabPayouts.textHaveNoPayout') }}
    </div>

  </b-card-text>
</template>

<script>
import dayjs from 'dayjs'
import {
  BCardText, BSpinner,
} from 'bootstrap-vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import _get from 'lodash/get'
import envMixin from '@/mixins/envMixin'

const MILESTONE_1 = '1000'
const MILESTONE_2 = '2000'
const MILESTONE_3 = '10000'

export default {
  components: {
    BCardText,
    BSpinner,
  },
  mixins: [numberFormatMixin, envMixin],
  props: {
    stats: {
      type: Array,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bonus: 0.00,
      fund: '18,602.52',
      clientName: 'Patrik Velich',
      clientId: '1567894567',
      m1: MILESTONE_1,
      m2: MILESTONE_2,
      m3: MILESTONE_3,
      dayjs,
    }
  },
  computed: {

    userBalance() {
      const amount = _get(this.user, ['data', 'balance'], 0)
      return this.amountFormat(amount).toLocaleString('en-US')
    },

    loading() {
      return _get(this.user, ['loading'], false)
    },

    fullName() {
      const fullName = _get(this.user, ['data', 'fullName'], '')
      const email = _get(this.user, ['data', 'email'], '')
      return fullName || email
    },

    userId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.userData.data?.localUserId || ''
    },

    code() {
      return this.userData.data.referralTracking?.code || ''
    },

    receivedAmount() {
      return this.userData.data.referralTracking?.receivedMoney || 0
    },

    clicks() {
      return this.userData.data.referralTracking?.countClick || 0
    },

    countUsed() {
      return this.userData.data.referralTracking?.countUsed || 0
    },
  },
  methods: {
    formatStatus(status) {
      return status === 'inprogress'
        ? 'In Progress'
        : 'Complete'
    },
    getStatusClass(status) {
      return status === 'inprogress'
        ? 'badge-light-warning'
        : 'badge-light-success'
    },
  },
}
</script>
<style scoped lang="scss">
.user-label{
  margin-bottom: 3px;
  font-size: 14px !important;
  font-weight: 500 !important;
}
</style>
