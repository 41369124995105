<template>
  <div>
    <div
      align-v="center"
      class=" referral-url position-relative"
    >
      <div
        class="content-referral bg-ecomdy"
        :style="{backgroundImage: checkBackground}"
      >
        <div class="d-md-block p-0">
          <h1 class="title-bg">
            {{ title }}
          </h1>
          <p class="sub-title-bg">
            {{ subHeading }}
          </p>
        </div>
        <div
          class="p-0"
        >
          <b-button
            pill
            variant="outline-primary"
            class="btn-tour-skip btn-skip input-height"
            href="#programs"
          >
            <div class="d-flex align-items-center">
              <span class="mr-25">
                {{ $t('referral.btnGoToReferral') }}
              </span>
              <feather-icon
                icon="ArrowDownIcon"
                size="24"
              />
            </div>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'

export default {
  components: {
    BButton,
  },

  mixins: [envMixin],

  props: {
    title: {
      type: String,
      required: true,
    },
    subHeading: {
      type: String,
      required: true,
    },
    imgUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    checkBackground() {
      // eslint-disable-next-line global-require
      return `url(${process.env.VUE_APP_INTRO_REFFERAL || require('@/assets/images/pages/referral/banner.png')})`
    },

  },
  methods: {
  },
}
</script>
<style scoped lang="scss">
.btn-skip {
  border: 1px solid #fff !important;
  color: #fff;
  &:hover {
    color: #6E44FF !important;
    background-color: #e0dbf1 !important;
  }

  @media (max-width: 991px){
    font-size: 14px;
  }
}

.referral-url {
  margin-bottom: 1px;
  height: 270px;

  @media (max-width: 991px){
    padding: 0;
    height: 250px;
  }
    .content-referral {
      color: #fff;
      padding-left: 70px;
      border-radius: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 991px){
        padding: 0 40px;
      }

      @media (max-width: 575px) {
        padding: 0 30px;
      }

      h1{
        color: #fff;
      }

      &.bg-ecomdy{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
      }

      .title-bg{
        font-size: 40px;
        margin-bottom: 1rem;

        @media (max-width: 991px){
          font-size: 25px;
        }

        @media (max-width: 575px) {
          margin-bottom: .5rem;
        }
      }

      .sub-title-bg{
        margin-bottom: 2rem;

        @media (max-width: 575px) {
          margin-bottom: 1rem;
        }
      }
    }
  }
</style>
