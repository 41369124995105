<template>
  <div class="referral px-1">

    <ReferralCard
      :title="$t('referral.titleBannerReferral')"
      :sub-heading="$t('referral.subTitleBannerReferral')"
      :img-url="referralUrl"
    />

    <b-row
      id="programs"
      class="mt-1"
    >
      <b-col class="tabs-mf  px-0">
        <b-tabs>
          <b-tab
            id="earn-money-tab"
            active
            :title="$t('referral.tabEarnMoney.titleTab')"
          >
            <EarnMoney :user-data="user" />
          </b-tab>
          <b-tab
            id="payouts-tab"
            :title="$t('referral.tabPayouts.titleTab')"
          >
            <payouts
              :stats="stats"
              :user-data="user"
            />
          </b-tab>
          <b-tab
            id="partner-program-tab"
            :title="$t('referral.tabPartnerProgram.titleTab')"
          >
            <b-card-text>
              <partner-program />
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol,
  BTabs, BCardText, BTab,
} from 'bootstrap-vue'
import ReferralCard from './components/ReferralCard.vue'
import Payouts from './components/Payouts.vue'
import PartnerProgram from './components/PartnerProgram.vue'
import EarnMoney from './components/EarnMoney.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BCardText,
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Payouts,
    PartnerProgram,
    ReferralCard,
    EarnMoney,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // referralUrl: require('@/assets/images/pages/referral/referral-program.svg'),
      referralUrl: require('@/assets/images/pages/referral/banner.png'),
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('referralProgram', ['stats']),
  },
  created() {
    this.getReferralStats()
  },
  methods: {
    async getReferralStats() {
      this.$store.dispatch('referralProgram/getReferralStats')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-referral.scss';
</style>
